@font-face {
  font-family: "bonvoyage";
  src: local("./components/bonvoyage.otf"),
    url("./components/bonvoyage.otf") format("opentype");
}

h1,
h2,
h3,
h4 {
  font-family: "bonvoyage", serif;
}

body {
  /* background-image: url("./media/background-blurred.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  min-height: 100vh;
}
